@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';























































































































































































@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
.loading {
  animation: spinner 0.6s linear infinite;
}
.widget-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 2rem 4rem;
  padding: 2rem 2rem;

  & + .widget-container {
    padding-top: 0;
  }
}
