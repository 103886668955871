@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';































































.v-card {
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr;
  min-height: 400px;
  max-width: 480px;
  margin: 0 auto;
}
.col {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.logo {
  border-radius: 0 !important;
  background-color: #3f51b5;
  img {
    width: 75%;
    max-width: 240px;
  }
}
