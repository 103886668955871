@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';























































































































































.switches .v-input--radio-group__input {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
