@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';























































































































































































































.wordmark {
  margin-left: 0.5rem;
  opacity: 0;
  transition: opacity 0.25s;

  .v-navigation-drawer--is-mouseover & {
    opacity: 1;
  }
}
