@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';





















.v-main {
  height: 100vh;
  overflow: hidden;
}
