@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';
































































































































































































































































































































































































































































































































































.lt-datepicker .v-icon.mdi-close {
  font-size: 16px;
}
