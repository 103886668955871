@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';











































































































































































































































































































































































































































































































































































































































































































































.main-content {
  width: 100%;
  overflow: hidden;
  max-height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;

  .container {
    max-height: 100%;
    display: grid;
    width: 100%;
    overflow: hidden;
    grid-template-rows: auto 1fr;
  }
  .v-sheet {
    max-height: 100%;
    overflow: hidden;
  }
  .v-data-table {
    max-height: 100%;
    display: grid;
    grid-template-rows: 1fr auto;
  }
  .v-data-table__wrapper {
    height: 100%;
    width: 100%;
    overflow: auto;
  }
}
#data-table {
  thead {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 3;
  }
  tr:not(.totals):hover {
    td {
      background: #f9f9fa;
      &:nth-child(1) {
        background: #f9f9fa;
      }
    }
  }
  td {
    &:nth-child(1) {
      background-color: #fff;
    }
  }
  td,
  th {
    white-space: nowrap;

    &:nth-child(1) {
      position: sticky;
      left: 0;
      z-index: 2;

      &:after {
        content: "";
        display: block;
        width: 3px;
        height: 100%;
        position: absolute;
        top: 0;
        right: -3px;
        background: linear-gradient(
          90deg,
          rgba(0, 0, 0, 0.15) 0%,
          rgba(255, 255, 255, 0) 100%
        );
      }
    }

    &:last-child {
      border-right: 0;
    }
  }
  .row-title {
    display: flex;
    justify-content: flex-start;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    .v-icon {
      margin-right: 0.5rem;
    }
  }
  .row-title__copy {
    display: flex;
    flex-direction: column;

    small {
      color: #757575;
    }
  }
  th {
    background-color: #ededf0;
    position: relative;
    &:first-child {
      z-index: 3;
    }

    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 3px;
      position: absolute;
      left: 0;
      bottom: -3px;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.15) 0%,
        rgba(255, 255, 255, 0) 100%
      );
    }
    span {
      margin-left: 4px;
    }
  }
  tr.totals {
    position: sticky;
    bottom: 0;
    left: 0;
    z-index: 2;

    td {
      background-color: #ededf0;
      position: relative;
      border-bottom: 0;

      &:before {
        content: "";
        display: block;
        width: 100%;
        height: 3px;
        position: absolute;
        left: 0;
        top: -3px;
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.15) 0%,
          rgba(255, 255, 255, 0) 100%
        );
      }

      &:first-child {
        position: sticky;
        z-index: 3;
      }
    }
  }
}
.status-icon {
  margin-right: 0.25rem;
}
.creative {
  display: flex;
  flex-direction: column;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.creative__headlines {
  color: blue;
  font-size: 13px;
  text-decoration: underline;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 30rem;
}
.creative__description {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-size: 11px;
}
.creative__url {
  color: #393;
  font-size: 11px;
  max-width: 30rem;
  word-break: break-all;
  white-space: pre-wrap;
}

.table-loading {
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
