@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';
















































































































































.v-application {
  .header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .header__icon {
    margin-right: 0.75rem;
  }
  .header {
    a {
      text-decoration-skip-ink: auto;
      text-decoration: none;
      color: rgba(0, 0, 0, 0.87);
      display: inline-block;

      &:hover {
        text-decoration: underline;
      }
    }
    ul {
      padding-left: 0;
    }
    li {
      display: inline-block;
      font-size: 1rem;
      line-height: 1;

      &:after {
        content: "|";
        display: inline-block;
        padding: 0 1rem;
      }

      &:last-child {
        font-weight: bold;
        &:after {
          display: none;
        }
      }
    }
  }
}
