@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';



























.big-number {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.big-number__title {
  font-size: 0.875rem;
  line-height: 1.125;
  margin-bottom: 0.5rem;
  font-weight: bold;
}
.big-number__number {
  font-weight: 900;
  line-height: 1.5;

  &.small {
    font-size: 2rem;
  }
  &.large {
    font-size: 2.5rem;
  }
}
